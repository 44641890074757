import React from 'react'

const hyperItalics = <span>Whelton PK, Carey RM, Aronow WS, et al. 2017 ACC/AHA/AAPA/ABC/ACPM/AGS/APhA/ASH/ASPC/NMA/PCNA guideline for the prevention, detection, evaluation, and management of high blood pressure in adults: a report of the American College of Cardiology/American Heart Association Task Force on Clinical Practice Guidelines.</span>

export const references = [
  {
    content:
      'Ohayon MM. Narcolepsy is complicated by high medical and psychiatric comorbidities: a comparison with the general population.',
    source: ' Sleep Med. ',
    info: '2013;14(6):488-492.',
  },
  {
    content:
      'Black J, Reaven NL, Funk SE, et al. Medical comorbidity in narcolepsy: findings from the Burden of Narcolepsy Disease (BOND) study.',
    source: ' Sleep Med. ',
    info: '2017;33:13-18.',
  },
  {
    content: hyperItalics,
    source: ' J Am Coll Cardiol. ',
    info: '2018;71(19):e127-e248.',
  },
  {
    content:
      'Jackson SL, King SM, Zhao L, Cogswell ME. Prevalence of excess sodium intake in the United States—NHANES, 2009-2012.',
    source: ' MMWR Morb Mortal Wkly Rep. ',
    info: '2016;64(52):1393-1397.',
  },
  {
    content:
      'Benjamin EJ, Muntner P, Alonso A, et al; American Heart Association Council on Epidemiology and Prevention Statistics Committee and Stroke Statistics Subcommittee. Heart disease and stroke statistics—2019 update: a report from the American Heart Association.',
    source: ' Circulation. ',
    info: '2019;139(10):e56-e528.',
  },
  {
    content:
      "Lloyd-Jones DM, Hong Y, Labarthe D, et al. Defining and setting national goals for cardiovascular health promotion and disease reduction: the American Heart Association's strategic Impact Goal through 2020 and beyond.",
    source: ' Circulation. ',
    info: '2010;121(4):586-613.',
  },
  {
    content:
      'Salehi-Abargouei A, Maghsoudi Z, Shirani F, Azadbakht L. Effects of Dietary Approaches to Stop Hypertension (DASH)-style diet on fatal or nonfatal cardiovascular diseases—incidence: a systematic review and meta-analysis on observational prospective studies.',
    source: ' Nutrition. ',
    info: '2013;29(4):611-618.',
  },
  {
    content: 'Quan SF. Sleep disturbances and their relationship to cardiovascular disease.',
    source: ' Am J Lifestyle Med. ',
    info: '2009;3(1 suppl):55s-59s.',
  },
  {
    content: 'Centers for Disease Control and Prevention. Sleep and sleep disorders.',
    source: ' Sleep and Chronic Disease. ',
    info: 'https://www.cdc.gov/sleep/about_sleep/chronic_disease.html. Page last reviewed: August 8, 2018.',
  },
  {
    content:
      'McAlpine CS, Kiss MG, Rattik S, et al. Sleep modulates haematopoiesis and protects against atherosclerosis.',
    source: ' Nature. ',
    info: '2019;566(7744):383-387.',
  },
  {
    content:
      'Dauvilliers Y, Jaussent I, Krams B, et al. Non-dipping blood pressure profile in narcolepsy with cataplexy.',
    source: ' PLoS One. ',
    info: '2012;7(6):e38977.',
  },
  {
    content:
      'Grimaldi D, Calandra-Buonaura G, Provini F, et al. Abnormal sleep-cardiovascular system interaction in narcolepsy with cataplexy: effects of hypocretin deficiency in humans.',
    source: ' Sleep. ',
    info: '2012:35(4):519-528.',
  },
  {
    content: 'Farquhar WB, Edwards DG, Jurkovitz CT, Weintraub WS. Dietary sodium and health: more than just blood pressure.',
    source: ' J Am Coll Cardiol. ',
    info: '2015;65(10):1042-1050.',
  },
  {
    content: 'Robinson AT, Edwards DG, Farquhar WB. The influence of dietary salt beyond blood pressure.',
    source: ' Curr Hypertens Rep. ',
    info: '2019;21(6):42.',
  },
]